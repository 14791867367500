<div *ngIf="!activeOnly || (activeOnly && !leftConversation)" class="conversation-card">
  <div matRipple [matRippleColor]="'rgba(0,0,0,0.04)'" class="convo-grid pt-1 pb-1"
    [ngClass]="{ selected: selected, unread: convo?.unreadMessageIds?.length }" *ngIf="!isHidden" [@slideInOut]>
    <div class="left">
      <ng-container *ngIf="type === 'TeamChat' && !isRolesSidenav; else userAvatar">
        <app-roles-icon></app-roles-icon>
      </ng-container>
      <ng-template #userAvatar>
        <app-celo-avatar [width]="40" [height]="40" [type]="type"
          [participant]="participantToShow ? participantToShow : ''"
          [showBadge]="participantToShow?.isExternal ? false : true"
          [conversationPictureUri]="!!this.convo.photoId && !leftConversation ? pictureUri : null"></app-celo-avatar>
      </ng-template>
    </div>

    <div class="mid">
      <div class="mid-contents">
        <div *ngIf="convo.patientData && !convo.type === 'External'" class="patient case-bubble celo-elipsis">
          <span class="case-title">Patient Case</span>
          <span class="case-content">
            <span>{{
              convo.patientData.uid ? convo.patientData.uid : ""
              }}</span>
            <span *ngIf="
                convo.patientData.uid &&
                (convo.patientData.firstName || convo.patientData.lastName)
              ">,
            </span>
            <span *ngIf="convo.patientData.firstName || convo.patientData.lastName">
              <span>
                {{ convo.patientData.firstName }}
                {{ convo.patientData.lastName }}
              </span>
            </span>
          </span>
        </div>
        <div class="name-field">
          <div class="name celo-elipsis">
            <span>
              {{
              isRolesSidenav
              ? participantToShow
              ? participantToShow.firstName +
              " " +
              participantToShow.lastName
              : "User"
              : name
              }}
            </span>
          </div>
          <div *ngIf="type === 'SelfChat'">&nbsp;(You)</div>
        </div>
        <div *ngIf="convo.lastMessage && !hideLastMessage" class="last-message celo-elipsis">
          <span *ngIf="convo.participants && convo.participants.length > 0">
            <span *ngIf="type !== 'Chat'">
              <span class="sender-name">{{ getParticipantNameById(convo.lastMessage.sentBy) }}:
              </span>
            </span>
            <span *ngIf="type === 'Chat'">
              <span *ngIf="
                  convo.lastMessage.sentBy !== 'SYSTEM' &&
                  convo.lastMessage.sentBy === userAccount.userId
                " class="sender-name">{{ getParticipantNameById(convo.lastMessage.sentBy) }}:
              </span>
            </span>
          </span>
          <span class="content" *ngIf="
              (!convo.lastMessage.type ||
                convo.lastMessage.type === 'UserConnected') &&
              convo.lastMessage?.content
            ">
            {{ convo.lastMessage.content }}
          </span>
          <span *ngIf="convo.lastMessage.type === 'Photo'" class="last-message-file">
            <span class="last-message-child">
              <span *ngIf="
                  convo.lastMessage.mediaType === 'Photo' ||
                  !convo.lastMessage.mediaType
                " class="message-type">Photo</span>
              <span *ngIf="convo.lastMessage.mediaType === 'Video'" class="message-type">Video</span>
              <img *ngIf="
                  convo.lastMessage.mediaType === 'Photo' ||
                  !convo.lastMessage.mediaType
                " [src]="
                  '/api/v2/media/' +
                    convo.lastMessage.metadata.photoId +
                    '?size=Small'
                    | clink
                    | secure
                " />
              <mat-icon *ngIf="convo.lastMessage.mediaType === 'Video'">videocam</mat-icon>
            </span>
          </span>
          <span *ngIf="convo.lastMessage.type === 'VideoCall'" class="last-message-video-call">
            <span class="last-message-child">
              <span>{{ convo.lastMessage.content }}</span>
              <!-- <span class="video-call-icon-container"><mat-icon>videocam</mat-icon></span> -->
            </span>
          </span>
          <span *ngIf="convo.lastMessage.type === 'PatientFile'" class="last-message-photo">
            <span>Document</span>
          </span>
        </div>
        <div *ngIf="showParticipants" class="last-message celo-elipsis">
          <span *ngIf="convo.participants && convo.participants.length > 0">
            <span *ngFor="let participant of convo.participants; let index = index" class="sender-name">
              <span *ngIf="index < 10 && !participant.leftOnUtc">{{ participant.firstName
                }}<span *ngIf="index < convo.participants.length - 1">,
                </span></span>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="timestamp">
        {{
        (convo.lastMessage?.sentOnUtc
        ? convo.lastMessage?.sentOnUtc
        : convo.createdOnUtc
        ) | dateFormat: "light"
        }}
      </div>

      <div class="notification-archive-container" *ngIf="inConversationList">
        <ng-container *ngIf="!isRolesSidenav && !disableConversationActions">
          <span class="settings-button">
            <i class="material-icons" (click)="$event.stopPropagation(); (false)" matTooltip="Settings"
              [matMenuTriggerFor]="appMenu">
              settings
            </i>
          </span>

          <span *ngIf="isMuted">
            <i [ngClass]="{ slide_r_0_4: isMuted }" matTooltip="Muted" class="material-icons muted-icon">
              volume_off
            </i>
          </span>

          <mat-icon [svgIcon]="'icon-pin'" [ngClass]="{ slide_r_0_4: isPinned }" class="pin-icon" *ngIf="isPinned"
            matTooltip="Pinned"></mat-icon>

          <mat-menu #appMenu="matMenu">
            <button mat-menu-item *ngIf="!isMuted" (click)="openMuteDialog()">
              <mat-icon [svgIcon]="'icon-mute'"></mat-icon>
              <span class="menu-item">Mute</span>
            </button>
            <button mat-menu-item *ngIf="isMuted" (click)="unMuteConversation()">
              <mat-icon [svgIcon]="'icon-unmute'" matTooltip="Unmute"></mat-icon>
              <span class="menu-item">Unmute</span>
            </button>
            <button mat-menu-item *ngIf="!isPinned" (click)="pinConversation(convo)">
              <mat-icon [svgIcon]="'icon-pin'"></mat-icon>
              <span class="menu-item">Pin</span>
            </button>
            <button mat-menu-item *ngIf="isPinned" (click)="unpinConversation(convo)">
              <mat-icon [svgIcon]="'icon-unpin'"></mat-icon>
              <span class="menu-item">Unpin</span>
            </button>
            <button mat-menu-item *ngIf="!hideArchiveOption" (click)="hideConvo(convo)">
              <mat-icon [svgIcon]="'icon-archive'"></mat-icon>
              <span class="menu-item">Archive chat</span>
            </button>
            <button mat-menu-item *ngIf="!hideUnreadCount" (click)="markReadDialogue(convo)">
              <mat-icon [svgIcon]="'icon-clear-badge'"></mat-icon>
              <span class="menu-item">Clear unread badge</span>
            </button>
          </mat-menu>
        </ng-container>

        <span>
          <div *ngIf="convo?.unreadMessageIds?.length && !hideUnreadCount"
            [ngClass]="{ slide_r_0_4: convo.unreadMessageIds.length }" class="notification">
            <div>
              {{ convo.unreadMessageIds.length }}
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="!hideBorder" class="line">
    <div class="celo-border-bottom"></div>
  </div>
</div>